<template>
  <div>
    <nav style="min-height: 80px" class="navbar is-info" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <h1 class="navbar-item is-size-2">ArchiGestor</h1>
        <button @click="menuEstaActivo = !menuEstaActivo" class="navbar-burger is-info button" aria-label="menu"
          aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div :class="{ 'is-active': menuEstaActivo }" class="navbar-menu">
        <div class="navbar-start">
          <div class="navbar-item">
            <div class="buttons">
              <a v-show="logueado" class="button is-info is-rounded" @click="navegarHacia('VerArchivos')">
                <b-icon class="mr-1" icon="archive"></b-icon>&nbsp;
                Mis archivos
              </a>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <a v-show="logueado && esAdministrador" class="button is-info is-rounded"
                @click="navegarHacia('VerUsuarios')">
                <b-icon class="mr-1" icon="account-multiple"></b-icon>&nbsp;
                Usuarios</a>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <a v-show="logueado && esAdministrador" class="button is-info is-rounded" @click="navegarHacia('AcercaDe')">
                <b-icon class="mr-1" icon="information"></b-icon>&nbsp;
                Acerca de</a>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <a target="_blank" rel="noreferrer" href="https://krdev.site/contact" class="button is-info is-rounded">
                <b-icon class="mr-1" icon="email"></b-icon>&nbsp;Soporte y ayuda</a>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <strong><a v-show="logueado" class="button is-danger is-rounded" @click="navegarHacia('Logout')">
                  Cerrar sesión<small></small>&nbsp;<b-icon class="mr-1" icon="logout"></b-icon></a></strong>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="section">
      <router-view />
    </div>
    <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Copyright 2023 - 2024 / Desarrollado por: <a target="_blank" class="has-text-black" href="https://krdev.site/contact">Christian Romero. KrDev</a> 
      </p>
      <hr>
        <a class="button is-white" href="https://instagram.com/chrix_ed" target="_blank"><b-icon icon="instagram"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://www.youtube.com/channel/UCTCNOn7-9vw5AAnYtwcykVA" target="_blank"><b-icon icon="youtube"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://twitter.com/chrix_ed_" target="_blank"><b-icon icon="twitter"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://github.com/krdev0" target="_blank"><b-icon icon="github"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://t.me/krdev0" target="_blank"><b-icon icon="telegram"></b-icon></a>
    </div>
  </footer>
  </div>
</template>

<script>

import EventBus from "@/EventBus";

export default {
  name: 'app',

  data: () => ({
    esAdministrador: false,
    logueado: false,
    deberiaMostrarMenu: false,
    correo: "",
    menuEstaActivo: false,
  }),
  mounted() {
    EventBus.$on("establecerUsuario", usuario => {
      this.refrescarUsuario(usuario);
    });
    EventBus.$on("cerrarSesion", () => {
      this.refrescarUsuario({});
    });
    EventBus.$on("navegarHacia", this.navegarHacia);
    EventBus.$on("mostrarMenu", this.mostrarMenu);
    EventBus.$on("ocultarMenu", this.ocultarMenu);
    this.obtenerUsuarioDeLocalStorage();
  },
  methods: {
    refrescarUsuario(usuario) {
      if (usuario.correo) {
        this.esAdministrador = Boolean(usuario.administrador);
        this.correo = usuario.correo;
        this.logueado = true;
      } else {
        this.esAdministrador = false;
        this.correo = "";
        this.logueado = false;
      }
    },
    obtenerUsuarioDeLocalStorage() {
      const usuario = EventBus.obtenerUsuario();
      this.refrescarUsuario(usuario);
    },
    mostrarMenu() {
      this.deberiaMostrarMenu = true;
    },
    ocultarMenu() {
      this.deberiaMostrarMenu = false;
    },
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
      this.menuEstaActivo = false;
    }
  },

}
</script>