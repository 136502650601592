<template>
  <section class="hero is-medium is-info">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">ArchiGestor</h1>
        <h2 class="subtitle">Almacenamiento de archivos en la nube</h2>
        <h2 style="margin-top: 10px;" class="subtitle">Enlaces sociales:</h2>
        <a class="button is-white" href="https://instagram.com/chrix_ed" target="_blank"><b-icon icon="instagram"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://www.youtube.com/channel/UCTCNOn7-9vw5AAnYtwcykVA" target="_blank"><b-icon icon="youtube"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://twitter.com/chrix_ed_" target="_blank"><b-icon icon="twitter"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://github.com/krdev0" target="_blank"><b-icon icon="github"></b-icon></a>
        <a style="margin-left: 15px;" class="button is-white" href="https://t.me/krdev0" target="_blank"><b-icon icon="telegram"></b-icon></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AcercaDe"
}
</script>
